import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { CardType } from "../../CardRenderer/models";
import RichText from "../../RichText";

import "./styles.scss";
import { IMAGE_SIZES } from "../../../constants";

interface LabelCardInterface {
  card: CardType;
}

const LabelCard: FunctionComponent<LabelCardInterface> = props => {
  const { card } = props;

  const animatedElement = useRef<HTMLDivElement>(null);
  const [isCardVisible, setIsCardVisible] = useState(false);

  const checkVisibility = () => {
    if (animatedElement.current) {
      const positionFromBottom = animatedElement.current.getBoundingClientRect().bottom;
      const positionFromTop = animatedElement.current.getBoundingClientRect().top;
      if ((positionFromBottom + positionFromTop) * 0.67 - window.innerHeight <= 0) {
        setIsCardVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkVisibility);
    window.addEventListener("resize", checkVisibility);
    return () => {
      window.removeEventListener("scroll", checkVisibility);
      window.removeEventListener("resize", checkVisibility);
    };
  }, []);

  return (
    <div className="c-labelCard" ref={animatedElement}>
      <div className={`c-labelCard__image-wrapper`}>
        <SanityImage
          {...card.image}
          sizes={IMAGE_SIZES.SMALL_SQUARE}
          width={300}
          height={300}
          htmlWidth={300}
          htmlHeight={300}
          className={`c-labelCard__image ${isCardVisible ? "un-zoomed" : ""}`}
        />
      </div>
      <div className="c-labelCard__details">
        <h3 className="c-labelCard__title text__size-h2">{card.title}</h3>
        <RichText data={card.description} />
      </div>
    </div>
  );
};

export default LabelCard;
