import React, { FunctionComponent } from "react";
import { ImageInterface, SanityCtaBlock } from "../../types/SanityTypes";
import Section from "../Section";
import AuthorListing from "./AuthorListing";
import LabelCard from "./LabelCard";
import CtaBlock from "../CtaBlock";

import "./styles.scss";

interface CardType {
  name: string;
  designation?: string;
  program?: string;
  bio?: any;
  image: ImageInterface;
  socials?: [
    {
      platform: string;
      link: string;
    }
  ];
  _type: string;
  title?: string;
  description?: string;
}

interface CardListInterface {
  title: string;
  subTitle?: string;
  listingType: string;
  _rawAuthor: CardType[];
  _rawCards: CardType[];
  bgColor: {
    title: string;
    value: string;
  };
  cardListCtaBlock?: SanityCtaBlock;
}

type CardRenderableSanityTypeNameToComponentMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [typeName: string]: FunctionComponent<any>;
};

interface CardRenderInterface {
  card: CardType;
  listingType: string;
}

const CardRenderer: FunctionComponent<CardRenderInterface> = ({ card, listingType }) => {
  const cardsMap: CardRenderableSanityTypeNameToComponentMap = {
    card: LabelCard,
    simplePersonSlide: AuthorListing
  };

  const getComponent = (cardType: string) => {
    const component = cardsMap[cardType];
    if (component) {
      return component;
    } else {
      console.error(`Unknown card type: ${cardType}`);
      return null;
    }
  };
  const Comp = getComponent(card._type);
  return Comp
    ? React.createElement(Comp, {
        card,
        listingType: listingType.replace("Listing", "")
      })
    : null;
};

const CardList: FunctionComponent<CardListInterface> = props => {
  const { listingType, bgColor, cardListCtaBlock } = props;
  const listing = listingType === "authorListing" ? props._rawAuthor : props._rawCards;
  const listingClass = "grid-col-12 grid-col-md-6 grid-col-xl-3";
  const handleDownload = () => {
    const link = document.createElement("a");
    link.download = cardListCtaBlock?._rawCtaFile.asset.originalFileName;
    link.href = cardListCtaBlock?._rawCtaFile.asset.url;
    link.target = "_blank";
    link.click();
  };
  const hasCta =
    listingType === "labelCard" &&
    cardListCtaBlock &&
    (cardListCtaBlock.ctaLink || cardListCtaBlock.externalCtaLink || cardListCtaBlock._rawCtaFile);

  return (
    <Section
      title={props.title}
      description={props.subTitle}
      theme={bgColor.title}
      sectionClassName={`cardList ${hasCta ? "cardList--with-cta" : ""}`}
    >
      <div className="grid-row grid-row-center">
        {listing.map((card: CardType, index: number) => (
          <div key={index} className={listingClass}>
            <CardRenderer {...props} card={card} listingType={listingType} />
          </div>
        ))}
      </div>
      {hasCta && (
        <div className="cardList__button-wrapper">
          <CtaBlock ctaBlock={cardListCtaBlock} ctaLabel={cardListCtaBlock.ctaLabel} />
        </div>
      )}
    </Section>
  );
};

export default CardList;
