import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { CardType } from "../../CardRenderer/models";

import "./styles.scss";
import { IMAGE_SIZES } from "../../../constants";

interface AuthorListingInterface {
  card: CardType;
}

const AuthorListing: FunctionComponent<AuthorListingInterface> = props => {
  const { card } = props;

  const animatedElement = useRef<HTMLDivElement>(null);
  const [isCardVisible, setIsCardVisible] = useState(false);

  const checkVisibility = () => {
    if (animatedElement.current) {
      const positionFromBottom = animatedElement.current.getBoundingClientRect().bottom;
      const positionFromTop = animatedElement.current.getBoundingClientRect().top;
      if ((positionFromBottom + positionFromTop) * 0.67 - window.innerHeight <= 0) {
        setIsCardVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkVisibility);
    window.addEventListener("resize", checkVisibility);
    return () => {
      window.removeEventListener("scroll", checkVisibility);
      window.removeEventListener("resize", checkVisibility);
    };
  }, []);
  return (
    <div className="c-authorListing" ref={animatedElement}>
      <div className="c-authorListing__image-wrapper">
        <SanityImage
          {...card.person.image}
          sizes={IMAGE_SIZES.SMALL_SQUARE}
          width={300}
          height={300}
          htmlWidth={300}
          htmlHeight={300}
          className={`c-authorListing__image ${isCardVisible ? "un-zoomed" : ""}`}
        />
      </div>
      <div className="c-authorListing__details">
        <h3 className="c-authorListing__name text__size-h4">{card.person.name}</h3>
        <p className="c-authorListing__description">{card.person.designation}</p>
      </div>
    </div>
  );
};

export default AuthorListing;
